import styled from 'styled-components'
import Table from '@/components/Table'
import Collapse from '@kunukn/react-collapse'

const StyledTable = styled(Table)`
  ${({ theme }) => `
    margin-top: 1rem;

    .table-head-cell,
    .table-cell {
      text-align: center;
    }

    .table-cell {
      justify-content: center;
    }

    

    ${theme.mediaQueries.phoneOnly} {
      text-align: right;

      .table-row {
        margin: 0;

        .table-row-inner {
          justify-content: flex-start;
          box-shadow: rgba(0,0,0,0.2) 0px 2px 4px 0px, rgba(0,0,0,0.1) 0px 0.3px 0.5px 0px;
        }

        .table-collapse-inner {
          padding: 0;

          .table-row {
            border: none;
            box-shadow: none;
          }

          .table-cell {
            width: 50%;
          }
        }
      }

    }
  `}
`

const TodayPriceWrap = styled.div`
  ${({ theme }) => `
    line-height: 1;

    &.red {
      .percentage, p { color: ${({ theme }) => theme.colors.pricingRed};}
      .percentage { background-color: ${({ theme }) => theme.colors.pricingRedBackground};}
    }

    &.amber {
      .percentage, p { color: ${({ theme }) => theme.colors.pricingAmber};}
      .percentage { background-color: ${({ theme }) => theme.colors.pricingAmberBackground};}
    }

    &.green {
      .percentage, p { color: ${({ theme }) => theme.colors.pricingGreen};}
      .percentage { background-color: ${({ theme }) => theme.colors.pricingGreenBackground};}
    }

    .percentage, p { 
      font-weight: 600; 
    }

    .percentage {
      padding: .2rem .8rem;
      margin-right: 1rem;
      border-radius: .2rem;
    }

    p { 
      display: inline-block; 
    }

    ${theme.mediaQueries.phoneOnly} {
      display: flex;
      flex-wrap: wrap;
      padding-right: 1.2rem;

      .percentage { 
        margin-right: .5rem;
        margin-bottom: .5rem;
      }
    }
  `}
`

const Season = styled.p`
  padding: .5rem; 
  border-radius: 2px;
  display: inline-block;
  height: 100%;
  word-break: break-word;

  &.winter { background-color: ${({ theme }) => theme.colors.pricingWinter}; }
  &.off-peak-january-february { background-color: ${({ theme }) => theme.colors.pricingOffPeakJanuaryFebruary}; }
  &.february-ht { background-color: ${({ theme }) => theme.colors.pricingFebruaryHt}; }
  &.off-peak-november-december { background-color: ${({ theme }) => theme.colors.pricingOffPeakNovemberDecember}; }
  &.xmas-and-nye { background-color: ${({ theme }) => theme.colors.pricingXmasAndNye}; }
  &.low { background-color: ${({ theme }) => theme.colors.pricingLow}; }
  &.autumn { background-color: ${({ theme }) => theme.colors.pricingAutumn}; }
  &.october-ht { background-color: ${({ theme }) => theme.colors.pricingOctoberHt}; }
  &.off-peak-october { background-color: ${({ theme }) => theme.colors.pricingOffPeakOctober}; }
  &.off-peak-september { background-color: ${({ theme }) => theme.colors.pricingOffPeakSeptember}; }
  &.spring { background-color: ${({ theme }) => theme.colors.pricingSpring}; }
  &.easter { background-color: ${({ theme }) => theme.colors.pricingEaster}; }
  &.post-easter { background-color: ${({ theme }) => theme.colors.pricingPostEaster}; }
  &.whitsun { background-color: ${({ theme }) => theme.colors.pricingWhitsun}; }
  &.off-peak-spring { background-color: ${({ theme }) => theme.colors.pricingOffPeakSpring}; }
  &.off-peak-may { background-color: ${({ theme }) => theme.colors.pricingOffPeakMay}; }
  &.mayday { background-color: ${({ theme }) => theme.colors.pricingMayday}; }
  &.summer { background-color: ${({ theme }) => theme.colors.pricingSummer}; }
  &.summer-peak { background-color: ${({ theme }) => theme.colors.pricingSummerPeak}; }
  &.off-peak-may-june-july { background-color: ${({ theme }) => theme.colors.pricingOffPeakMayJuneJuly}; }
  &.peak { background-color: ${({ theme }) => theme.colors.pricingPeak}; }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    text-align: ${({ isSingleChar }) => (isSingleChar ? 'left' : 'center')};
    padding: ${({ isSingleChar }) => (isSingleChar ? '0' : '0.5rem')}
  }
`

const TickIcon = styled.span`
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 50%;
  margin-left: 0.5rem;
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  vertical-align: text-bottom;

  &::before, &::after {
    height: 1px;
    content: " ";
    position: absolute;
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &::before {
    width: 5px;
    left: 3px;
    top: 10px;
    transform: rotate(45deg);
  }

  &::after {
    width: 10px;
    left: 6px;
    top: 9px;
    transform: rotate(-45deg);
  }
`

const MobileItem = styled.div`
  margin-top: 1rem;
  border-radius: .2rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 5px 0px ${({theme}) => theme.colors.pricingBoxShadow};
  font-size: 0.875rem;


  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    box-shadow: rgba(0,0,0,0.2) 0px 2px 4px 0px, rgba(0,0,0,0.1) 0px 0.3px 0.5px 0px;
  }
`

const MobileItemContent = styled.div`
min-height: 45px;
cursor: pointer;

  ${({ isActive }) => `
    margin: 1rem;
    display: flex;
    position: relative;
    align-items: center;

    & > * {
      flex: 1 1;
      min-width: 0;
    }

    .mobile-item-date, 
    .mobile-item-income, 
    ${TodayPriceWrap} {
      opacity: 1;
      transition: 0.2s all ease;
      justify-content: flex-start;
    }

    ${isActive ? `
      .mobile-item-income, ${TodayPriceWrap} {
        opacity: 0;
      }

      .mobile-item-date {
        font-size: 0.875rem;
        font-weight: bold;
      }
    ` : ''}

    @media only screen and (max-width: 340px) {
      & > * {
        flex: auto;
      }

      .mobile-item-income {
        margin: 0 1rem;
      }
    }
  `}
`

const MobileItemCollapse = styled(Collapse)`
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
`

const MobileItemCollapseRow = styled.div`
  display: flex;
  margin: 1rem;
  font-size: 0.875rem;

  span { 
    flex: 1;
    font-weight: bold;
  }


`

const Chevron = styled.span`
  ${({ isActive, theme }) => `
      right: 0;
      width: 10px;
      height: 10px;
      display: block;
      cursor: pointer;
      position: absolute;

      &::before {
          top: 0;
          right: 0;
          bottom: 0;
          content: "";
          width: 10px;
          margin: auto;
          height: 10px;
          display: block;
          position: absolute;
          transition: 0.2s transform ease;
          border-bottom: 2px solid ${theme.colors.blackTwo};
          border-right: 2px solid ${theme.colors.blackTwo};
          transform: ${isActive ? 'rotate(-135deg)' : 'rotate(45deg)'}; 
      } 
  `}
`
const StyledPercentage = styled.div`
margin-left: 6px;
background-color: ${(props) =>
    props.calculatedPercentageDiff < 0 ? '#FDECC9' : '#C8E0CE'};
padding: 0px 4px 0px 4px;
border-radius: 4px;


`

const MobileLastCellWrapper = styled.div`
  display: flex;
  align-items: center;
`

export { StyledTable, TodayPriceWrap, Season, TickIcon, MobileItem, MobileItemContent, MobileItemCollapse, MobileItemCollapseRow, Chevron, StyledPercentage, MobileLastCellWrapper }
