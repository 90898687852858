import { useInfiniteQuery } from '@tanstack/react-query'
import { queryFnBookings } from './useQueryBookings'
import { queryFnClosures } from './useQueryClosures'
import dayjs from 'dayjs'

const MAX_YEARS_BEFORE = 1
const MAX_YEARS_AFTER = 2

export function getPreviousStartEndDate (prevStartDate: string, _prevEndDate: string, earliestStartDate: string) {
  if (prevStartDate === earliestStartDate) {
    return undefined
  }

  const startDate = dayjs(prevStartDate).subtract(6, 'month').startOf('month').format('YYYY-MM-DD')
  const endDate = dayjs(prevStartDate).subtract(1, 'day').format('YYYY-MM-DD')

  if (startDate < earliestStartDate) {
    return { startDate: earliestStartDate, endDate }
  }

  return { startDate, endDate }
}

export function getNextStartEndDate (_prevStartDate: string, prevEndDate: string, latestsEndDate: string) {
  if (prevEndDate === latestsEndDate) {
    return undefined
  }

  const startDate = dayjs(prevEndDate).add(1, 'day').format('YYYY-MM-DD')
  const endDate = dayjs(prevEndDate).add(6, 'month').endOf('month').format('YYYY-MM-DD')

  if (endDate > latestsEndDate) {
    return { startDate, endDate: latestsEndDate }
  }

  return { startDate, endDate }
}

export type BookingsPage = {
  bookings: {
    fromDate: string;
    toDate: string;
    bookings: any[];
  };
  closures: any[];
  pageParam: {
    startDate: string;
    endDate: string;
  };
}

export const baseKeyBookingsInfinite = ['booking-calendar']
export const queryKeyBookingsInfinite = (input) => [...baseKeyBookingsInfinite, input]

export const queryFnBookingsInfinite = async ({ startDate, endDate, propertyId, unitId }) => {
  const [bookings, closures] = await Promise.all([
    queryFnBookings({ startDate, endDate, propertyId, unitId, searchBy: 'arrival' }),
    queryFnClosures({ startDate, endDate, propertyId, unitId })
  ])
  return { bookings, closures }
}

export const useInfiniteQueryBookings = ({ year, propertyId, options, unitId }) => {
  const startDateInitial = dayjs(new Date()).startOf('month').subtract(1, 'month').format('YYYY-MM-DD')
  const endDateInitial = `${year}-12-31`
  const initialPageParam = { startDate: startDateInitial, endDate: endDateInitial }

  const earlistStartDate = `${year - 1 - MAX_YEARS_BEFORE}-12-01`
  const latestsEndDate = `${year + MAX_YEARS_AFTER}-12-31`

  return useInfiniteQuery({
    initialPageParam,
    queryKey: ['booking-calendar', propertyId, unitId],
    queryFn: async ({ pageParam = { startDate: startDateInitial, endDate: endDateInitial } }) => {
      const { bookings, closures } = await queryFnBookingsInfinite({ ...pageParam, propertyId, unitId })
      return { bookings, closures, pageParam }
    },

    getPreviousPageParam: (page: BookingsPage) => {
      const prevStartDate = page.bookings.fromDate
      const prevEndDate = page.bookings.toDate

      return getPreviousStartEndDate(prevStartDate, prevEndDate, earlistStartDate)
    },
    getNextPageParam: (page: BookingsPage) => {
      const prevStartDate = page.bookings.fromDate
      const prevEndDate = page.bookings.toDate

      return getNextStartEndDate(prevStartDate, prevEndDate, latestsEndDate)
    },
    ...options
  })
}

export default useInfiniteQuery
