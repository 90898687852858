import React, { useState, useEffect, useMemo } from 'react'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import {
  ErrorMessage
} from '../bookingModals.styles'
import PropTypes from 'prop-types'
import toast from '@/helpers/toast'
import { OvalLoadingSpinner } from '@/components/__UI'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { 
  DATE_FORMAT_DEFAULT,
  DATE_FORMAT_INCLUDE_DAY 
} from '@/constants/dates'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { FormikField } from '@/components/__Inputs'
import PassengerForm from '@/components/__Forms/PassengerForm'
import {
  BookingSection,
  AlertWarning,
  Section,
  SectionData,
  SectionDropdown,
  SubSection,
  SubSectionFullWidth,
  SectionLabelUnit,
  BookingForm,
  ButtonWrapper,
  StyledButton,
  SpinnerTextWrapper,
  StyledHeader,
  StyledDurationLabel
} from './NewBookingModalConsolidated.styles'
import { useUserBrand } from '@/hooks/useUserBrand/useUserBrand'
import { BOOKING_MAX_DURATION } from '@/constants/bookings'
import { SelectDropdownDS, SelectRadioGroupDS } from '@/components/__Inputs/SelectDropdown'
import { useMutationCreateOwnerBooking } from '@/hooks/useQuery/mutations/useMutationCreateBooking'
import { useQueryBookingQuote, useQueryBookingDurations } from '@/hooks/useQuery'
import { useTranslation } from 'react-i18next'
import QuoteSectionComponent from './Components/Quote'
import { Text, Heading } from '@awaze/design-system.components.text'
import { Label, InputDescription } from '@awaze/design-system.components.input'
import { AlertBanner } from '@awaze/design-system.components.alert-banner'
import {
  Modal,
  ModalBody,
  ModalContent
} from '@awaze/design-system.components.modal'
import { capitaliseAllWords } from '@/helpers/stringUtils'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const NewBookingModalConsolidated = ({
  onClose,
  onSuccess,
  property,
  selectedDate,
  managedProperty,
  allowPassengers,
  unitCode,
  unitId,
  hasChartPermissions
}) => {
  const { t } = useTranslation()

  const bookingTypeOptions = [
    {
      label: t('includeCleaning'),
      value: 'true',
      hint: (
        <Text size={200}>
          {t('cleaningMeassage')}
        </Text>
      )},
    {
      label: t('noCleaning'),
      value: 'false',
      hint: (
        <Text size={200}>
          {t('noCleaningMeassage')}
        </Text>
      )}
  ]

  const VALIDATION_SCHEMA = Yup.object({
    bookingTypeSelect: Yup.string().required(t('pleaseSelectOption')),
    durationSelect: Yup.string().nullable().required(t('pleaseSelectDuration')),
    passengerToggle: Yup.bool(),
    titleSelect: Yup.string().when('passengerToggle', {
      is: true,
      then: Yup.string().required(t('titleForPassengerRequired'))
    }),
    forename: Yup.string().when('passengerToggle', {
      is: true,
      then: Yup.string().required(t('forenameForPassengerRequired'))
    }),
    surname: Yup.string().when('passengerToggle', {
      is: true,
      then: Yup.string().required(t('surnameForPassengerRequired'))
    })
  })

  const initialValues = {
    durationSelect: null,
    titleSelect: '',
    passengerToggle: false,
    forename: '',
    surname: '',
    bookingTypeSelect: managedProperty ? 'true' : 'false'
  }

  const [duration, setDuration] = useState(0)
  const [chosenDays, setChosenDays] = useState(false)
  const [withClean, setWithClean] = useState(bookingTypeOptions[0].value)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [holidayPrice, setHolidayPrice] = useState(null)
  const [hasHolidayPrice, setHasHolidayPrice] = useState(null)
  const [extrasObj, setExtrasObj] = useState([])

  const { isNovasol } = useUserBrand()

  const isEnabledBookingQuote = !!(duration && property && selectedDate)
  const { data: bookingQuoteData, isLoading: isLoadingBookingQuote, error: errorBookingQuote } = useQueryBookingQuote({
    propertyId: property ? property.propertyId : null,
    fromDate: dayjs(selectedDate).format(DATE_FORMAT_DEFAULT),
    toDate: dayjs(selectedDate).add(duration, 'day').format(DATE_FORMAT_DEFAULT),
    unitId: unitId},
  {
    enabled: isEnabledBookingQuote
  })
  const isLoadingAndEnabledBookingQuote = isEnabledBookingQuote && isLoadingBookingQuote

  const {
    data: bookingDurationData,
    isLoading: isLoadingBookingDurations,
    error: errorBookingDurations
  } = useQueryBookingDurations(
    {
      propertyId: property ? property.propertyId : null,
      fromDate: dayjs(selectedDate).format(DATE_FORMAT_DEFAULT),
      unitId: unitId,
      maxDuration: BOOKING_MAX_DURATION
    },
    {
      enabled: !!(property && selectedDate && unitId)
    }
  )

  const { mutate: createOwnerBooking } = useMutationCreateOwnerBooking({
    onSuccess: (data) => {
      onSuccess && onSuccess({...data})
      onClose && onClose()
    },
    onError: (error) => {
      onClose && onClose()
      toast.error(t('bookingFailed'), null, error)
    },
    onSettled: () => {
      setIsLoading(false)
    }
  })

  const allowablefDurations = isLoadingBookingDurations ? [] : bookingDurationData.filter(d => d.isValidBookingDuration).map(d => ({
    value: d.duration,
    text: `${d.duration} ${d.duration > 1 ? t('nights').toLocaleLowerCase() : t('night').toLocaleLowerCase()}`
  }))

  const getExtraQuantity = (extra) => {
    return extra.selectionMode === 'Compulsory' ? 0 : (extra.itemQty || 0)
  }

  const handleSubmit = async (values) => {
    const {bookingTypeSelect, durationSelect, titleSelect, forename, surname, passengerToggle, passengerToggleForExtras} = values
    const hasClean = bookingTypeSelect === 'true'
    const leadPassenger = {}

    setIsLoading(true)

    if (passengerToggle) {
      leadPassenger.title = titleSelect.value
      leadPassenger.forename = forename
      leadPassenger.surname = surname
    }

    const bookingData = {
      startDate: dayjs(selectedDate).format(DATE_FORMAT_DEFAULT),
      endDate: dayjs(selectedDate).add(durationSelect, 'day').format(DATE_FORMAT_DEFAULT),
      requiresCleaning: hasClean,
      unitId: unitId,
      leadPassenger: (allowPassengers && passengerToggle) ? leadPassenger : null,
      extras: passengerToggleForExtras ? extrasObj.map(extra => ({
        id: extra.id,
        amount: extra.amount,
        name: extra.name,
        quantity: getExtraQuantity(extra),
        currencyCode: extra.currencyCode
      })) : null
    }

    createOwnerBooking({ propertyId: property?.propertyId, bookingData })
  }

  const handleOnClose = () => {
    setIsDisabled(true)
    setIsLoading(false)
    onClose && onClose()
  }

  useEffect(() => {
    if (bookingQuoteData) {
      const {
        holidayPrice,
        hasHolidayPrice,
        allowBooking,
        extras
      } = bookingQuoteData

      setExtrasObj(extras)
      setIsDisabled(!allowBooking)
      setHasHolidayPrice(hasHolidayPrice)
      setHolidayPrice(holidayPrice ?? null)
    } else if (errorBookingQuote) {
      setIsDisabled(true)
    }
  }, [bookingQuoteData, errorBookingQuote, isLoadingBookingQuote])

  const getSumOfExtrasItems = (extras) => {
    if (!extras) return

    return parseFloat(extras.map(extra => extra.amount * (extra.itemQty || 0))
      .reduce((a, b) => a + b, 0)) || parseFloat(0)
  }

  const getTotalAmount = () => {
    return (holidayPrice + getSumOfExtrasItems(extrasObj)).toFixed(2)
  }

  const {formattedArrivalDate, formattedDepartureDate} =
   useMemo(() => {
     const arrivalDate = dayjs(selectedDate)
     const formattedArrivalDate = capitaliseAllWords(arrivalDate.format(DATE_FORMAT_INCLUDE_DAY))

     const departureDate = arrivalDate.add(duration, 'day')
     const formattedDepartureDate = capitaliseAllWords(departureDate.format(DATE_FORMAT_INCLUDE_DAY))

     return { formattedArrivalDate, formattedDepartureDate }
   }, [selectedDate, duration])

  if (isLoading || isLoadingBookingDurations) {
    return (
      <Modal isOpen onClose={onClose}>
        <ModalContent>
          <ModalBody>
            <SpinnerTextWrapper>
              <OvalLoadingSpinner />
              {isLoading && (
                <Text sizes={300}>
                  {t('creatingBooking')}
                </Text>
              )}
            </SpinnerTextWrapper>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  const currencySymbols = {
    'GBP': '£'
  }

  return (
    <Modal isOpen close={onClose}>
      <ModalContent header={<StyledHeader>{t('createOwnerBooking')}</StyledHeader>}>
        <ModalBody>
          <BookingForm
            id="NewBookingForm"
            validationSchema={VALIDATION_SCHEMA}
            handleSubmit={handleSubmit}
            submitError={null}
            initialValues={initialValues}
          >

            {bookingQuoteData?.hasBookingExcessCharge && (
              <AlertWarning>
                <AlertBanner variant={'caution'} data-testId={'caution-banner'}>
                  <Text
                    sizes={200}
                    type="bold"
                    mb={6}
                  >{t('bookingAllowanceExceeded')}</Text>
                  <Text sizes={200}>
                    {t('excessCharge', { currencyAmount: `${currencySymbols[bookingQuoteData.currency] || bookingQuoteData.currency}${bookingQuoteData.bookingExcessChargeAmount.toFixed(2)}` })}
                  </Text>
                </AlertBanner>
              </AlertWarning>
            )}
            {property && (
              <>
                <Text sizes={100} isHeading>{isNovasol ? property.propertyId : property.propertyName}</Text>
                {!isNovasol && <Text sizes={200}>{property.propertyCode}</Text>}
              </>
            )}
            {
              unitCode && (
                <>
                  <SectionLabelUnit>
                    {t('unit')} : {unitCode}
                  </SectionLabelUnit>
                </>
              )
            }
            <BookingSection>
              <Section>
                <SubSection>
                  <Label type='mid'>{t('arrival')}</Label>
                  <SectionData>
                    {formattedArrivalDate}
                  </SectionData>
                </SubSection>
                <SubSection>
                  <Label type='mid'>{t('departure')}</Label>
                  <SectionData mb={8}>
                    {duration === 0 ? <Text sizes={300} color='ColorGrey500'>{t('specifyDuration')}</Text> : formattedDepartureDate}
                  </SectionData>
                </SubSection>
              </Section>

              <Section>
                <SubSection>
                  <StyledDurationLabel>{t('duration')}</StyledDurationLabel>
                  <InputDescription>{t('bookableDurationMessage')}.</InputDescription>
                  <SectionDropdown>
                    <FormikField
                      component={SelectDropdownDS}
                      inputType="select"
                      showErrorIcon
                      name='durationSelect'
                      options={[{options: allowablefDurations}]}
                      onChange={e => {
                        setDuration(e.target.value)
                        setChosenDays(true)
                      }}
                      placeholder={t('selectDuration')}
                      isOptionDisabled={option => option.disabled}
                      isDesignSystemComponent
                      disabled = {allowablefDurations.length === 0}
                    />
                  </SectionDropdown>
                </SubSection>
              </Section>

              {(<>
                {managedProperty && (
                  <Section>
                    <SubSectionFullWidth>
                      <Heading sizes={100}>{t('cleaningService')}</Heading>
                      <SectionData mb={8}>
                        <FormikField
                          component={SelectRadioGroupDS}
                          inputType="select"
                          name='bookingTypeSelect'
                          variant="tappable"
                          orientation="column"
                          options={bookingTypeOptions}
                          defaultValue={withClean}
                          onChange={(val) => {
                            setWithClean(val.target.value)
                          }}
                          isOptionDisabled={option => option.disabled}
                          isDesignSystemComponent
                          disabled = {allowablefDurations.length === 0}
                        />
                      </SectionData>
                    </SubSectionFullWidth>
                  </Section>
                )}

                {allowPassengers &&
                <PassengerForm
                  extrasObj = {extrasObj}
                  setExtrasObj = {setExtrasObj}
                  chosenDays = {chosenDays}
                  hasHolidayPrice = {hasHolidayPrice}
                  duration={duration}
                />}

              </>)}
            </BookingSection>

            {allowablefDurations.length < 1 && (
              <ErrorMessage data-testid="dateNotAvailableError">
                <p>{t('dayUnavailableForBooking')}</p>
              </ErrorMessage>
            )}

            {(errorBookingQuote || errorBookingDurations) &&
        <ErrorMessage>
          <p>{t('newBookingError')}</p>
        </ErrorMessage>
            }

            {hasChartPermissions && duration !== 0 &&
          <QuoteSectionComponent
            quoteDetails={{
              currency: bookingQuoteData?.currency,
              holidayPrice: parseFloat(holidayPrice),
              extrasTotal: parseFloat(getSumOfExtrasItems(extrasObj)),
              total: parseFloat(getTotalAmount())
            }}
            isDisabled={isDisabled && !hasHolidayPrice}
            isLoading={isLoadingBookingQuote}
            isError={errorBookingQuote}
          />
            }

            <ButtonWrapper>
              <StyledButton
                data-testid='newBookingModalCancelButton'
                onClick={handleOnClose}
                isFullWidth
                text={t('cancel')}
                variant="outline"
                colourScheme='brand-primary'
              />

              <StyledButton
                data-testid='newBookingModalBookButton'
                id='newBookingModalBookButton'
                isFullWidth
                type="submit"
                form="NewBookingForm"
                isDisabled={isDisabled || isLoadingAndEnabledBookingQuote}
                text={t('book')}
                colourScheme='brand-primary'
              >
              </StyledButton>
            </ButtonWrapper>

          </BookingForm>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

NewBookingModalConsolidated.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  property: PropTypes.object,
  selectedDate: PropTypes.object,
  managedProperty: PropTypes.bool,
  hasChartPermissions: PropTypes.bool,
  allowPassengers: PropTypes.bool,
  unitCode: PropTypes.string,
  unitId: PropTypes.string
}

export default NewBookingModalConsolidated
