import React, { memo } from 'react'
import { generateRandomKey } from '@/helpers/stringUtils'
import {
  Td,
  DayNumber,
  Segment, 
  Today,
  WeekNumber
} from './Calendar.styles'
import dayjs from 'dayjs'
import { isChangeoverDay, isDateInPast, isDateWithinUnavailableRanges } from './CalendarUtils'
import { DATE_FORMAT_DEFAULT } from '@/constants/dates'
import PropTypes from 'prop-types'

const BuildRows = memo(({
  date,
  yearCalendar,
  unavailableDates,
  onDayClick, onSegmentClick,
  disabled = false,
  isFirstMonth = false,
  findSegments,
  hoveredID,
  setHoveredID,
  showWeekNumber }) => {
  const startOfMonth = Number(date.startOf('month').format('d')) - 1
  const blanks = []
  const daysInMonth = []
  const rows = []
  let cells = []

  const yearViewMonthLength = 37

  const iterate = startOfMonth < 0 ? 6 : startOfMonth

  for (let i = 0; i < iterate; i++) {
    blanks.push(<Td key={generateRandomKey()} inActive blankCell yearCalendar={yearCalendar} showTopBorder={isFirstMonth}></Td>)
  }

  for (let j = 1; j <= date.daysInMonth(); j++) {
    const day = dayjs(`${date.year()}-${date.month() + 1}-${j}`)
    const unavailable = isDateInPast(day)
    const closed = isDateWithinUnavailableRanges(day, unavailableDates)
    const dayOfWeek = day.day()

    const isToday = day.format(DATE_FORMAT_DEFAULT) === dayjs().format(DATE_FORMAT_DEFAULT)
    const segments = findSegments(day)
    const isInactive = segments.some(segment => segment.type !== 'END')

    daysInMonth.push(
      <Td
        key={generateRandomKey()}
        onClick={() => {
          if (!unavailable && !closed && onDayClick && !isInactive && !disabled) {
            onDayClick(day)
          }
        }}
        inActive={isInactive}
        unavailable={unavailable}
        closed={closed}
        disabled={disabled}
        yearCalendar={yearCalendar}
        showTopBorder={isFirstMonth}
      >
        {segments.map((segment, i) => (
          <Segment
            key={i}
            type={segment.type}
            $state={segment.state}
            $isHovered={hoveredID === segment.id}
            yearCalendar={yearCalendar}
            onMouseEnter={() => setHoveredID(segment.id)}
            onMouseLeave={() => setHoveredID(null)}
            changeover={isChangeoverDay(segments)}
            onClick={(e) => {
              if (onSegmentClick) {
                e.stopPropagation()
                onSegmentClick(segment)
              }
            }}
            data-testid={`state-${segment.state}`} />
        ))}
        <DayNumber
          today={isToday}
          unavailable={unavailable}
          closed={closed}
          isHovered={segments.some(x => x.id === hoveredID)}
          data-testid={'day ' + j}
        >
          {j}
        </DayNumber>
        {isToday && <Today />}
        {dayOfWeek === 1 && showWeekNumber && (
          <WeekNumber data-testid={'week ' + day.week()}>
            {day.week()}
          </WeekNumber>
        )}      </Td>)
  }

  const allRows = [...blanks, ...daysInMonth]

  if (yearCalendar) {
    cells.push(...allRows)

    while (cells.length < yearViewMonthLength) {
      cells.push(<Td key={generateRandomKey()} inActive blankCell yearCalendar={yearCalendar} showTopBorder={isFirstMonth}></Td>)
    }
    rows.push(cells.slice(0, yearViewMonthLength))
  } else {
    allRows.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row)
      } else {
        rows.push(cells)
        cells = []
        cells.push(row)
      }

      if (i === allRows.length - 1) {
        rows.push(cells)
      }
    })
  }

  return rows.map((d, i) => <tr key={generateRandomKey()}>{d}</tr>)
})

BuildRows.propTypes = {
  date: PropTypes.object.isRequired,
  yearCalendar: PropTypes.bool,
  unavailableDates: PropTypes.array.isRequired,
  onDayClick: PropTypes.func,
  onSegmentClick: PropTypes.func,
  disabled: PropTypes.bool,
  isFirstMonth: PropTypes.bool,
  findSegments: PropTypes.func.isRequired,
  hoveredID: PropTypes.string,
  setHoveredID: PropTypes.func.isRequired
}

export default BuildRows
